import { motion as m } from "framer-motion";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Button from "@mui/material/Button";
import { buttonStyleGreen } from "../utils/buttonStyle";
import ContactInfoCard from "./ContactInfoCard";

function ContactInfo() {
  return (
    <div
      className="absolute h-full flex flex-col items-center justify-evenly
    w-5/6 text-white lg:w-2/5"
    >
      <div
        className="p-4 w-full text-2xl flex flex-col items-center justify-center 
        rounded bg-[#0000004a] lg:text-4xl lg:w-3/5"
      >
        <div className="overflow-hidden">
          <m.h2
            initial={{ y: "100%" }}
            animate={{ y: "0" }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            Contact
          </m.h2>
        </div>
      </div>
      <ContactInfoCard
        title="Adresă:"
        details="Baia Mare, Strada Vasile Alecsandri nr. 89, Maramureș"
      />
      <ContactInfoCard
        title="Telefon:"
        details={
          <a href="tel:0362-120.112" className="text-sky-400 text-2xl">
            0362-120.112
          </a>
        }
      />
      <ContactInfoCard title="Orar:" details="Luni - Vineri: 10:00 - 18:00" />
      <a href="https://optidora.programari.cloud.onlineservice.io/">
        <Button
          variant="contained"
          color="success"
          startIcon={<ScheduleIcon />}
          sx={buttonStyleGreen}
          className="btn-main"
        >
          Programare On-line
        </Button>
      </a>
    </div>
  );
}

export default ContactInfo;
