import ErrorIcon from "@mui/icons-material/Error";

const Anulare3 = () => {
  return (
    <div className="redirect">
      <ErrorIcon color="error" sx={{ scale: "1.4" }} />
      <h2 className="m-10 text-xl text-center">
        Programarea dvs. nu se mai poate anula deoarece au fost efectuate
        operațiuni asupra ei.
      </h2>
    </div>
  );
};

export default Anulare3;
