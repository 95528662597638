export const buttonStyleGreen = {
  padding: "0.5rem 1rem",
  letterSpacing: "0.5px",
  backgroundColor: "#409720",
};

export const buttonStyleWhite = {
  padding: "0.5rem 1rem",
  letterSpacing: "0.5px",
  border: "1px solid white",
  color: "white",
};
