import MainTitle from "../TitleButtons";
import "./background.css";

function Background() {
  return (
    <div className="background">
      <div className="background-image">
        <img src="images/bg.png" />
      </div>
      <MainTitle />
    </div>
  );
}

export default Background;
