import ErrorIcon from "@mui/icons-material/Error";

const Anulare2 = () => {
  return (
    <div className="redirect">
      <ErrorIcon color="info" sx={{ scale: "1.4" }} />
      <h2 className="m-10 text-xl text-center">
        Programarea dvs. este deja anulată.
      </h2>
    </div>
  );
};

export default Anulare2;
