import "./pages.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion as m } from "framer-motion";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import IconButton from "@mui/material/IconButton";
import CalitateaOpti from "../components/Detalii/CalitateaOpti";
import Despre from "../components/Detalii/Despre";
import Experienta from "../components/Detalii/Experienta";
import Footer from "../components/Detalii/Footer";
import Map from "../components/Detalii/Map";
import OchelariPersonalizati from "../components/Detalii/OchelariPersonalizati";
import Produse from "../components/Detalii/Produse";
import Servicii from "../components/Detalii/Servicii";
import TitleButtons from "../components/TitleButtons";
import ContactForm from "../components/ContactForm";
import Background from "../components/Background/Background";

function Detalii() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= window.innerHeight) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div>
      <div className="contact">
        <Background />
        <TitleButtons />
      </div>
      <m.div className="detalii">
        {showTopBtn && (
          <IconButton
            sx={{
              position: "fixed",
              top: window.innerWidth > 768 ? "15%" : '10%',
              right: window.innerWidth > 768 ? "5%" : '7%',
              zIndex: "1",
              transform: "scale(1.3)",
              color: "#2c2c2c",
            }}
            onClick={goToTop}
          >
            <ArrowCircleUpIcon />
          </IconButton>
        )}
        <Despre />
        <CalitateaOpti />
        <Servicii />
        <Produse />
        <OchelariPersonalizati />
        <Experienta />
        <ContactForm />
        <div className="lg:w-screen">
          <Map />
          <Footer />
        </div>
      </m.div>
    </div>
  );
}

export default Detalii;
