import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Confirm = () => {
  return (
    <div className="redirect">
      <CheckCircleIcon color="success" sx={{ scale: "1.4" }} />
      <h2 className="m-10 text-xl text-center">
        Programarea dvs. a fost realizată cu succes!
      </h2>
    </div>
  );
};

export default Confirm;
