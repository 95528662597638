const TermeniConditii = () => {
  return (
    <div className="mt-[12vh] p-4 lg:px-14 lg:py-8">
      <h2 className="text-2xl text-center mb-4 lg:mb-8">TERMENI ȘI CONDIȚII</h2>
      <p>
        Bine ati venit pe site-ul web www.optidora.ro, site deținut de către
        societatea comercială OPTICA OPTIDORA SRL, având sediul social în
        Maramureș, Baia Mare, Str. Vasile Alecsandri nr. 89, Cod Poștal 430351,
        CUI RO33355530, număr de înregistrare la Registrul Comerțului
        J24/618/2014, e-mail contact@optidora.ro și telefon 0362-120112.
        <br />
        <br />
        Prin accesarea acestui site web și/sau a oricărei pagini a acestuia
        sunteți de acord cu condițiile de utilizare descrise mai jos.
        <br />
        <br />
        Informațiile din acest site web au un scop informativ general și nu
        garantăm de exactitatea lor la un moment dat, deși se va încerca pe cât
        posibil ca la publicarea lor pe site toate informațiile sa fie exacte
        iar remedierea eventualelor erori să se realizeze în timp util. Pentru
        eventuale aspecte lămuritoare concrete nu ezitați să ne contactați
        folosind mijloacele de comunicare publicate.
        <br />
        <br />
        Toate informațiile, produsele sau aplicațiile conținute în acest site
        sunt proprietatea OPTICA OPTIDORA SRL sau ale furnizorilor ori
        partenerilor noștri, după caz și prin urmare site-ul este protejat de
        legea drepturilor de autor, toate drepturile fiind rezervate. Este
        interzisă copierea, modificarea, afișarea, distribuirea, transmiterea,
        publicarea, comercializarea, licentierea, crearea unor materiale
        derivate sau utilizarea conținutului site-ului în orice scop fără
        confirmarea scrisă din partea deținătorului legal OPTICA OPTIDORA SRL.
        <br />
        <br />
        Ne rezervăm dreptul de a modifica conținutul și/sau structura site-ului
        în orice moment și fără nici o informare prealabilă.
        <br />
        <br />
        Informatiile prezentate pe www.optidora.ro sunt de interes general și
        sunt puse la dispoziția utilizatorilor în mod gratuit. Prin termenul
        ”utilizator” al acestei pagini se înțelege orice persoană fizică sau
        juridică care va accesa pagina.
        <br />
        <br />
        Societatea OPTICA OPTIDORA SRL nu este responsabilă pentru nici un fel
        de daune directe sau indirecte produse prin utilizarea site-ului
        www.optidora.ro.
        <br />
        <br />
        Orice persoană care dorește să-și procure unul din serviciile sau
        produsele prezentate în site este rugată să contacteze OPTICA OPTIDORA
        SRL prin unul din mijloacele afișate în pagina de Contact a site-ului,
        pentru a se informa atât asupra disponibilității serviciului sau
        produsului în cauză, cât și asupra condițiilor contractuale, tarifelor
        și informațiilor tehnice sau de altă natură.
        <br />
        <br />
        Serviciul de programare on-line presupune colectarea din partea
        dumneavoastră a următoarelor date cu caracter personal: nume, prenume,
        dată naștere, telefon e-mail. Aceste date vor fi stocate și prelucrate
        de către OPTICA OPTIDORA SRL în acord cu prevederile Legii 677/2001 și
        directivelor GDPR pentru protectia persoanelor cu privire la prelucrarea
        datelor cu caracter personal și libera circulatie a acestor date.
        <br />
        <br />
        Informațiile furnizate de către persoanele care solicită programări sau
        informații trebuie să fie corecte. Corectarea sau actualizarea unor
        informații furnizate eronat de către clienții noștri se realizează prin
        contactarea OPTICA OPTIDORA SRL prin unul din mijloacele de contact
        afișate.
        <br />
        <br />
        OPTICA OPTIDORA SRL tratează cu maximă seriozitate solicitările fiecărei
        persoane, în condițiile legitimității acestora. Ne asumăm o
        responsabilitate permanentă în realizarea consultațiilor cu
        profesionalism și la orarul asumat dar ne rezervăm dreptul unor nedorite
        întârzieri sau chiar anulări a unor servicii programate dacă acestea nu
        pot fi onorate din cauza unor motive independente de dorința noastră. Nu
        ne asumăm obligații financiare față de clienții a căror serviciu
        programat nu a putut fi astfel onorat din cauze independente de OPTICA
        OPTIDORA SRL. Persoanelor aflate în astfel de situații nedorite li se
        vor oferi programări alternative la o dată ulterioară, în limita
        locurilor disponibile.
        <br />
        <br />
        Orice persoană care vizitează site-ul www.optidora.ro si care oferă date
        sau informatii cu caracter personal prin intermediul acestui site,
        telefonic sau e-mail de natura celor menționate mai sus își manifestă în
        mod expres și neechivoc acordul pentru stocarea și prelucrarea acestor
        date și informații personale de către OPTICA OPTIDORA SRL; transmiterea
        de materiale promoționale specifice operațiunilor de marketing direct;
        soluționarea de către OPTICA OPTIDORA SRL a cererilor, întrebărilor și
        reclamațiilor adresate; alte activități întreprinse de OPTICA OPTIDORA
        SRL și permise de lege, ce nu fac obiectul unei aprobări din partea
        destinatarului.
        <br />
        <br />
        OPTICA OPTIDORA SRL își rezervă dreptul să procedeze la validarea
        programării serviciilor prin contactare telefonică, SMS sau e-mail.
        Prezentarea pacientului pentru consultație se va realiza în ziua și la
        ora stabilită pentru programare.
        <br />
        <br />
        Pacientul poate solicita anularea sau modificarea programării inițiale
        folosind orice mijloc de contact publicat. Programările ce nu pot fi
        onorate de către pacienți trebuie anunțate și anulate cât mai devreme
        posibil, ideal fiind cu cel puțin 24 de ore înainte de ora programată a
        consultației.
        <br />
        <br />
        Clienții OPTICA OPTIDORA SRL dispun de drepturile prevăzute de
        Regulamentul UE 2016/679 privind protecția persoanelor fizice în ceea ce
        privește prelucrarea datelor cu caracter personal, respectiv dreptul la
        informare, dreptul de acces, dreptul de rectificare, dreptul de
        restricționare a prelucrării, dreptul de ștergere, dreptul de
        portabilitate a datelor și dreptul de opoziție, pe care le poate
        exercita printr-o cerere scrisă adresată către OPTICA OPTIDORA SRL.
        Orice solicitare de acest tip se va trimite de către client prin e-mail
        la adresa contact@optidora.ro, în atenția responsabilului pentru
        prelucrarea datelor personale.
        <br />
        <br />
        Pagina www.optidora.ro nu colectează cookies.
        <br />
        <br />
        Pentru orice nelămurire în legătură cu exercitarea drepturilor
        dumneavoastră referitoare la utilizarea site-ului și la protecția
        utilizării vă rugăm să ne contactați prin intermediul secțiunii Contact
        din site.
      </p>
    </div>
  );
};

export default TermeniConditii;
