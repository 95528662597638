import { motion as m } from "framer-motion";

function ContactInfoCard({ title, details }) {
  return (
    <div className="w-full rounded bg-[#0000004a] p-8 lg:text-2xl">
      <div className="overflow-hidden">
        <m.h4
          initial={{ y: "100%" }}
          animate={{ y: "0" }}
          transition={{ duration: 0.4, delay: 0.3, ease: "easeOut" }}
          className="text-lg"
        >
          {title}
        </m.h4>
      </div>
      <div className="overflow-hidden">
        <m.p
          initial={{ y: "100%" }}
          animate={{ y: "0" }}
          transition={{ duration: 0.4, delay: 0.6, ease: "easeOut" }}
          className="text-xl pt-2"
        >
          {details}
        </m.p>
      </div>
    </div>
  );
}

export default ContactInfoCard;
