import { motion as m } from "framer-motion";

function CalitateaOpti() {
  return (
    <div className="text-center my-16 flex flex-col items-center">
      <h2 className="text-lime-600 text-2xl mb-2">Calitatea OPTIDORA</h2>
      <h3 className="text-green-700 text-4xl font-bold">
        Experiență și profesionalism
      </h3>
      <div className="w-4/5 lg:flex lg:flex-wrap lg:mt-2">
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_01.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              Tulburările de vedere pot exista la orice vârstă.
            </p>
            <p>
              Vedere neclară la distanță, oboseală la citit, ore multe petrecute
              în fața calculatorului, indiferent de problemă programează un
              consult la OptiDora.
            </p>
          </div>
        </div>
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_02.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              Solicită o programare telefonic sau chiar la sediul nostru.
            </p>
            <p>
              Programul nostru flexibil va veni în întâmpinarea solicitării
              tale.
            </p>
          </div>
        </div>
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_03.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              Tehnologia de ultimă generație alături de profesionalismul
              optometriștilor îți vor crea o experiență deosebită.
            </p>
          </div>
        </div>
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_04.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              Acuratețea măsurătorilor efectuate și experiența optometriștilor
              te vor ajuta să obții o prescripție perfect adaptată nevoilor
              tale.
            </p>
          </div>
        </div>
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_05.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              În alegerea ramei pentru ochelari vom face echipă.
            </p>
            <p>
              Îți vom prezenta nenumărate opțiuni de design, materiale și preț
              pentru a găsi cea mai bună variantă pentru tine. Scopul este ca tu
              să te simți confortabil din toate punctele de vedere.
            </p>
          </div>
        </div>
        <div className="text-gray-800 lg:p-6 lg:flex  lg:w-1/2  lg:h-[50vh] lg:mb-6">
          <div className="overflow-hidden rounded-md m-10 lg:m-0">
            <m.img
              initial={{ y: "100%" }}
              whileInView={{ y: "0" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="w-full h-full"
              src="images/experienta/experienta_06.png"
              alt="experienta optidora"
            />
          </div>
          <div className="lg:w-3/5  lg:flex lg:flex-col lg:justify-center lg:p-4">
            <p className="font-bold text-gray-800 pb-2">
              Materialul, grosimea, colorările, tratamentele aplicate, mono sau
              multifocale, toate acestea sunt importante în obținerea
              ochelarilor personalizați pentru tine. La final, tu ești fericitul
              posesor al unei perechi noi de ochelari iar noi suntem mândri că
              tu ești clientul nostru.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalitateaOpti;
