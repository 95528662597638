import ErrorIcon from '@mui/icons-material/Error';

const Anulare4 = () => {
  return (
    <div className="redirect">
      <ErrorIcon color="error" sx={{ scale: "1.4" }} />
      <h2 className="m-10 text-xl text-center">
        Programarea nu a putut fi anulată, vă rugăm reîncercați!
      </h2>
    </div>
  );
};

export default Anulare4;
