import Button from "@mui/material/Button";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Link } from "react-router-dom";
import { motion as m } from "framer-motion";

const buttonStyle = {
  padding: "0.5rem 1rem",
  letterSpacing: "0.5px",
  backgroundColor: "#409720",
};

function TitleButtons() {
  return (
    <div
      className="h-full pb-8 absolute text-white 
      flex flex-col items-center justify-evenly
      lg:w-3/4"
    >
      <div className="h-1/4 flex flex-col items-center justify-around">
        <div className="overflow-hidden">
          <m.h1
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="font-bold text-5xl lg:text-7xl"
          >
            OptiDora
          </m.h1>
        </div>
        <div className="overflow-hidden">
          <m.h3
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
            className="font-bold text-xl lg:text-2xl"
          >
            ARE GRIJĂ DE OCHII TĂI
          </m.h3>
        </div>
      </div>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
        className="flex flex-col items-center justify-around h-2/4 lg:scale-110"
      >
        <a href="https://optidora.programari.cloud.onlineservice.io/">
          <Button
            variant="contained"
            color="success"
            startIcon={<ScheduleIcon />}
            sx={buttonStyle}
            className="btn-main"
          >
            Programare On-line
          </Button>
        </a>
      </m.div>
    </div>
  );
}

export default TitleButtons;
