import { useEffect } from "react";
import { Link } from "react-router-dom";
import Background from "../components/Background/Background";
import ContactForm from "../components/ContactForm";
import ContactInfo from "../components/ContactInfo";
import Map from "../components/Detalii/Map";
import "./pages.css";

function Contact() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="contact">
        <Background />
        <ContactInfo />
      </div>
      <ContactForm />
      <div className="lg:w-screen">
        <Map />
        <div className="flex flex-col items-center justify-center lg:text-center w-screen h-[10vh] bg-neutral-800">
          <div className="text-white underline text-xs lg:right-8 lg:text-sm">
            <Link to="/termeni-conditii">Termeni și condiții</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
