import { useEffect, useState } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

function Map() {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // });
  // const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [map, setMap] = useState(null);

  useEffect(() => {
    const getMap = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/staticmap?center=47.6462569,23.5783892&zoom=15&size=800x500&scale=2&markers=47.6462569,23.5783892&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        setMap(response.url);
        console.log(response.url);
      } catch (error) {
        console.log("Map::getMap::", error);
      }
    };
    getMap();
  }, []);

  return (
    <div className="w-screen h-[50vh] lg:h-[58vh] bg-neutral-800">
      {map ? (
        <a
          className="flex justify-center w-full h-full"
          href="https://www.google.com/maps/place/OptiDora/@47.6462569,23.5783892,15z/data=!4m14!1m7!3m6!1s0x4737dc0d11ac4519:0x5842ffdb6c2fd35b!2sOptiDora!8m2!3d47.6462569!4d23.5783892!16s%2Fg%2F11bwqbymg_!3m5!1s0x4737dc0d11ac4519:0x5842ffdb6c2fd35b!8m2!3d47.6462569!4d23.5783892!16s%2Fg%2F11bwqbymg_"
        >
          <img src={map} alt="map" className="object-cover w-full h-full" />
        </a>
      ) : (
        "Harta nu a fost incarcata"
      )}
    </div>
  );
}

export default Map;
