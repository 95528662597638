import "./navbar.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <m.nav>
      <div
        className="logo"
        onClick={() => {
          navigate("/");
          navActive && setNavActive(false);
        }}
      >
        <img src="images/logo.png" />
      </div>
      {navActive && (
        <div
          className="background-blur"
          onClick={() => setNavActive(false)}
        ></div>
      )}
      {location.pathname === "/" ? (
        <ul className={navActive ? "nav-active" : ""}>
          <Link
            to={location.pathname === "/" ? "/contact" : "/"}
            onClick={() => setNavActive(!navActive)}
          >
            {location.pathname === "/" ? "Contact" : "Acasă"}
          </Link>
          <HashLink
            smooth
            to="#despre"
            onClick={() => setNavActive(!navActive)}
          >
            Despre noi
          </HashLink>
          <HashLink
            smooth
            to="#servicii"
            onClick={() => setNavActive(!navActive)}
          >
            Servicii
          </HashLink>
          <HashLink
            smooth
            to="#produse"
            onClick={() => setNavActive(!navActive)}
          >
            Produse
          </HashLink>
          <HashLink
            smooth
            to="#ochelari"
            onClick={() => setNavActive(!navActive)}
          >
            Ochelari personalizati
          </HashLink>
        </ul>
      ) : (
        <ul className={navActive ? "nav-active" : ""}>
          <Link
            to={location.pathname === "/" ? "/contact" : "/"}
            onClick={() => setNavActive(!navActive)}
          >
            {location.pathname === "/" ? "Contact" : "Acasă"}
          </Link>
        </ul>
      )}
      <div
        className={navActive ? "burger toggle" : "burger"}
        onClick={() => setNavActive(!navActive)}
      >
        <img src={navActive ? "images/icon-x.png" : "images/icon-burger.png"} />
      </div>
    </m.nav>
  );
}

export default Navbar;
